import axios from "axios";

const api=axios.create({
  baseURL: "https://pixell.leadmi.pixell.cc/api",
  // baseURL: "https://teste.leadmi.pixell.cc/api",
});

export const api2=axios.create({
  baseURL: "https://roletaback.pixelltm.com/api/",
  // baseURL: "http://localhost:8000/api/",
});

export default api;
