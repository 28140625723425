export default function Wheel(){
    return (
        <div id="wheel-wrapper">
            <div className="wheel" id="wheel">
                <div className="center"></div>
                <div className="faixa">
                    <span>1 mês de marketing digital</span>
                </div>
                <div className="faixa">
                    <span>2 meses de CRM com WPP</span>
                </div>
                <div className="faixa">
                    <span>1 landing page</span>
                </div>
                <div className="faixa">
                    <span>6 posts de social media</span>
                </div>
                <div className="faixa">
                    <span>Gestão de 3 anúncios</span>
                </div>
                <div className="faixa">
                    <div>
                        <span>30% de desconto</span>
                    </div>
                </div>
            </div>
            <div id="seta"></div>
        </div>
    );
}
