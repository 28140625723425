import { useEffect, useState } from "react";
import api from '../services/api';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export default function Form({rodando,setRodando,prize,numPremios,carregandoDadosIniciais,jaParticipou,name,setName,telefone,setTelefone}){
    const [cargo,setCargo]=useState('');
    useEffect(async ()=>{
        if(prize){
            try{
                let observation='';
                if(prize==='Não foi dessa vez'){
                    observation=`Não ganhou prêmio`;
                }else{
                    observation=`Ganhou o prêmio: ${prize}`;
                }

                await api.post('/origem/1b09688a-1d57-4aec-a829-09ad56c554e5',{
                    name,
                    observation,
                    token: "TK_51AE3807FF384E8E84876DB6B397532B_leadmi",
                    nome_card: name,
                    observacoes: observation,
                    contatos: [
                        {
                            nome: name,
                            cargo: cargo,
                            email: '',
                            telefone: '55'+telefone.replace(/\D/g,''),
                        },
                    ],
                }).then((response)=>{
                    if(prize==='Não foi dessa vez'){
                        MySwal.fire({
                            title: 'Que pena',
                            html: `Infelizmente não foi dessa vez`,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        });
                    }else{
                        MySwal.fire({
                            title: `Parabéns ${name}`,
                            html: `Um representante de nosso time entrará em contato para combinar a entrega do prêmio.
                            <br />
                            Você ganhou: <b>${prize}</b>`,
                            icon: 'success',
                            confirmButtonText: 'Ok',
                        });
                    }
                }).catch((error)=>{
                    MySwal.fire({
                        title: 'Erro!',
                        text: 'Por favor, tente novamente mais tarde',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                });
                setName('');
                setTelefone('');
                setCargo('');
            }catch(error){
                console.log(error);
            }
        }
    },[prize]);

    function handleSubmit(e){
        e.preventDefault();
        if(!name){
            MySwal.fire({
                title: 'Atenção',
                text: 'É necessário informar o seu nome',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            document.getElementById('name').focus();
            return;
        }
        if(!telefone){
            MySwal.fire({
                title: 'Atenção',
                text: 'É necessário informar o seu telefone',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            document.getElementById('telefone').focus();
            return;
        }
        if(telefone.replace(/\D/g,'').length<10){
            MySwal.fire({
                title: 'Atuação',
                text: 'Telefone inválido',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            document.getElementById('telefone').focus();
            return;
        }
        if(cargo===''){
            MySwal.fire({
                title: 'Atuação',
                text: 'É necesario informar o seu cargo',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            document.getElementById('cargo').focus();
            return;
        }

        setRodando(true);

        /**
         * conversão do google
         */
        window.dataLayer = window.dataLayer || [];
        function gtag(){
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'AW-342099233');
        function gtag_report_conversion(url) {
            var callback = function () {
                if (typeof (url) != 'undefined') {
                    window.location = url;
                }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-342099233/2ziVCP_K-5MYEKGKkKMB',
                'event_callback': callback
            });
            return false;
        }
        gtag_report_conversion();
    }

    return (
        <>
            {!prize?(
                <>
                    <h2><span>Gire a roleta e</span><br />ganhe prêmios!</h2>
                    <p>Prencha os dados abaixo e clique em "GIRAR A ROLETA"</p>
                </>
            ):null}
            {carregandoDadosIniciais?(
                <p>Por favor aguarde</p>
            ):(
                <>
                    {prize?(
                        <div style={{textAlign: 'center', color: '#F2F2F2', paddingTop: '15px', paddingBottom: '15px'}}>
                            {prize==='Não foi dessa vez'?(
                                <p>Infelizmente não foi dessa vez</p>
                            ):(
                                <>
                                    <h2>Parabéns!</h2>
                                    <p>O seu prêmio é: <strong>{prize}</strong></p>
                                </>
                            )}
                        </div>
                    ):(
                        <>
                            {numPremios===0?(
                                <p>Todos os prêmios já foram sorteados</p>
                            ):(
                                <>
                                    {jaParticipou?(
                                        <>
                                            <p>Você já participou do sorteio, em breve iremos entrar em contato.</p>
                                            <p>Muito obrigado</p>
                                        </>
                                    ):(
                                        <form className="form-roleta" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input type="text" id="name" value={name} disabled={rodando} placeholder="Nome" title="Nome" onChange={e=>setName(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <InputMask placeholder="Telefone" disabled={rodando} title="Telefone" id="telefone" mask="(99) 99999 9999" maskChar=" " onChange={e=>setTelefone(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <select id="cpf" value={cargo} disabled={rodando} title="Cargo na empresa" onChange={e=>setCargo(e.target.value)}>
                                                    <option value="">Cargo na empresa</option>
                                                    <option value="Diretor / Sócio">Diretor / Sócio</option>
                                                    <option value="Gerente / Gestor">Gerente / Gestor</option>
                                                    <option value="Marketing / Vendas">Marketing / Vendas</option>
                                                    <option value="Outro">Outro</option>
                                                </select>
                                            </div>
                                            <div style={{padding: '0 15px'}}>
                                                {rodando?(
                                                    <button type="submit" disabled>
                                                        Aguarde...
                                                    </button>
                                                ):(
                                                    <button type="submit">
                                                        Girar a roleta
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}
