import { useState, useEffect } from 'react';
import './App.css';
import Form from './components/Form';
import Roleta from './components/Roleta';
import {api2} from './services/api';

function App() {
  const [rodando,setRodando]=useState(false);
  const [deg,setDeg]=useState(0);
  const [prize,setPrize]=useState('');
  const [numPremios,setNumPremios]=useState(123);
  const [carregandoDadosIniciais,setCarregandoDadosIniciais]=useState(true);
  const [jaParticipou,setJaParticipou]=useState(false);

  const [name,setName]=useState('');
  const [email,setEmail]=useState('');
  const [telefone,setTelefone]=useState('');

  let currentDeg=deg;
  let counter=0;
  let speed=0;
  let time=Math.floor(Math.random()*(130-80+1)+80);
  let intervalId=null;

  useEffect(()=>{
    if(rodando){
      spin();
    }
  },[rodando]);

  useEffect(async ()=>{
    await api2.post('premios/num_premios').then(response=>{
      setNumPremios(response.data.qtd);
    });
    setCarregandoDadosIniciais(false);
  },[]);

  async function spin(){
    const response=await api2.post(`premios/sortear?name=${name}&telefone=${telefone}&email=${email}`);
    if(response.data.status==='error'&&response.data.message==='Você já participou do sorteio'){
      setJaParticipou(true);
      return;
    }

    /**
     * tempo girando
     */
    const tempos={
      '1': 79,//
      '2': 73,//
      '3': 70,//
      '4': 94,//
      '5': 89,//
      '6': 84,//
    };
    time=tempos[response.data.premio.id];

    intervalId=setInterval(()=>{
      if(counter<8){
        speed=Math.pow((counter/2),2);
      }
      if(counter>=time&&speed>0){
        speed-=0.5;
        speed=speed<0?0:speed;
      }

      document.getElementById('wheel').style.transform=`rotate(${currentDeg}deg)`;
      currentDeg=(currentDeg+speed)%360;
      if(speed===0&&counter>=time){
        setDeg(currentDeg);
        setRodando(false);
        clearInterval(intervalId);
        setPrize(response.data.premio.nome);
      }
      counter++;
    },46);
  }

  return (
    <div className="container">
      <div>
        <Form 
          rodando={rodando} 
          setRodando={setRodando} 
          prize={prize} 
          numPremios={numPremios} 
          carregandoDadosIniciais={carregandoDadosIniciais} 
          jaParticipou={jaParticipou} 
          name={name} 
          setName={setName} 
          telefone={telefone} 
          setTelefone={setTelefone} />
      </div>
      <div>
        <Roleta rodando={rodando} setRodando={setRodando} />
      </div>
    </div>
  );
}

export default App;
